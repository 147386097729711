<template>
	<video autoplay muted loop class="video-bg">
		<source :src="source.src" :type="source.type" :key="key" v-for="(source, key) in sources" />
	</video>
</template>

<script>
export default {
	name: 'VideoBg',
	props: {
		sources: Array,
	},
}
</script>

<style scoped lang="scss">
.video-bg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	background: color_('primary', 'dark');
}
</style>
