<template>
	<div class="partial partial--stride stride">
		<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:body class="stride__body">
				<div class="stride__section stride__section--1">
					<div class="stride__hero-wrapper">
						<hero class="stride__hero">
							<figure>Transforming the Industry</figure>
							<h1>Architects of  <br> Innovation</h1>
						</hero>
					</div>
					<video-bg :sources="[{src: `${publicPath}video/DTCC_Annual_Report_rocket.mp4`, type:'video/mp4'}]" />
				</div>
				<div class="stride__section stride__section--2">
					<div class="stride__content stride__content--right">
						<div class="box">
							<h2>To the <span>moon</span></h2>
							<p>When U.S. President John F. Kennedy set the ambitious goal of landing a man on the moon by the end of the 1960s, the hopes and dreams of hundreds of millions of people rested on the shoulders of James E. Webb. As the new Administrator of NASA, Webb was about to embark on one of the most formidable engineering and management feats in history.</p>
						</div>
					</div>
				</div>
				<div class="stride__section stride__section--3">
					<div class="stride__content stride__content--left">
						<div class="box">
							<h2>Examine, Embrace, <span>Create</span></h2>
							<p>The NASA team quickly mobilized, establishing collaborative partnerships between government, private industry and universities to tap the deepest well of human knowledge. They examined current tools and legacy technology, embraced computers and transistors and created new systems, blending creativity and imagination with science and math to build spacecrafts that could soar to the Moon and explore the galaxy.</p>
						</div>
					</div>
				</div>
				<div class="stride__section stride__section--4">
					<div class="stride__content stride__content--right">
						<div class="box">
							<h2><span>One</span> Giant Leap</h2>
							<p>On July 20, 1969, Apollo 11 astronauts Neil Armstrong and Buzz Aldrin stepped onto the moon’s surface, taking <em>“one small step for a man, one giant leap for mankind.”</em> That moment represented a triumph of vision, daring and ingenuity. It also inspired future generations to dream, experiment and achieve their own <em>“moonshots.”</em></p>
						</div>
					</div>
				</div>
				<div class="stride__section stride__section--5">
					<div class="stride__content stride__content--left">
						<div class="box">
							<h2>Reimagining <span>tomorrow</span></h2>
							<p>At DTCC, we are inspired by history’s great discoveries and seek to capture that same flash of imagination, invention and vision to our work. We recognize that building the global marketplace of tomorrow requires seeing beyond what we know today, opening our minds to new possibilities and reimagining what the future looks like.</p>
							<br><br>

							<h2 class="small"><span>Join us for a tour into</span> <br> DTCC’s strategic transformation</h2>

							<a href="#" class="btn btn--primary">Launch Plan</a>
						</div>
					</div>

					<div class="stride-side"><img src="~@/assets/stride/strideSideImg.png" width="688" /></div>
				</div>
			</template>
		</article-wrapper>
	</div>
</template>

<script>
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'
import VideoBg from '@/components/VideoBg'

export default {
	name: 'PartialStride',
	components: {
		ArticleWrapper,
		Hero,
		VideoBg
	},
	data: () => ({
		publicPath: process.env.BASE_URL,
	})
}
</script>

<style scoped lang="scss">
.stride {
	scroll-snap-type: y mandatory;
	overflow-y: auto;

	&__hero {
		z-index: 2;
		&-wrapper {
			position: relative;
			overflow: hidden;
		}
	}

	&__section {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 100vh;
		scroll-snap-align: start;

		&--1 {

		}

		&--2 {
			background-image: url('~@/assets/stride/bg2@2x.jpg');
			background-color: #000;
		}

		&--3 {
			background-image: url('~@/assets/stride/bg3@2x.jpg');
		}

		&--4 {
			background-image: url('~@/assets/stride/bg4@2x.jpg');
		}

		&--5 {
			background-image: url('~@/assets/stride/bg5@2x.jpg');
			color: #fff;
			position: relative;
			overflow: hidden;

			.stride__content {

				h2 {
					color: #fff !important;

					span {
						color: color_('secondary');
					}
				}

				p {
					@include fontSize(18px);
					@include gutter('margin-bottom', 1.5);
					line-height: 1.5;
					margin-top: 0;
				}


				.btn--primary {
					background-color: color_('secondary');
					display: inline-block;
				}

				.box {
					background: transparent;
					box-shadow: none;
				}
			}

			.stride-side {
				display: none;


				@include media-query-min-width('tablet') {
					display: block;
					position: absolute;
					right: 0;
					top: 5%;
					max-width: 420px;
				}

				@include media-query-min-width('desktop') {
					max-width: 688px;
					right: -5%;
					top: 5%;
				}
			}
		}
	}


	&__content {
		@include gutter('padding-left', 2);
		@include gutter('padding-right', 2);
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		height: 100%;

		display: flex;
    	flex-direction: column;
		justify-content: center;

		&--right {
			align-items: flex-end;
		}


		@include media-query-min-width('tablet') {

		}

		@include media-query-min-width('desktop') {
			max-width: $content-container-lg;

		}

		.box {
			@include gutter('padding', 1.5);
			background: #fff;
			box-shadow: 0 0 24px rgba(0, 0, 0, .16);
			border-radius: 20px;
			margin-bottom: 16px;
			width: 100%;

			h2 {
				margin-top: 0;
			}

			p {
				margin-bottom: 0;
			}

			@include media-query-min-width('tablet') {
				max-width: 450px;
			}

			@include media-query-min-width('desktop') {
				max-width: 548px;
			}
		}

		p {
			@include gutter('margin-bottom', 1.5);
			margin-top: 0;
		}

		h2 {
			@include fontSize(48px);
			@include gutter('margin-bottom', 1);
			margin-top: 0;
			color: color_('primary','dark');
			font-family: $font-family-secondary;
			font-weight: 900;
			line-height: 1;
			text-transform: uppercase;

			&.small {
				@include fontSize(32px);
				color: color_('primary','dark');
			}

			&.large {
				@include fontSize(64px);
				color: color_('primary','dark');
			}

			span {
				color: color_('primary');
			}

			@include media-query-max-width('phone') {
				br {
					display: none;
				}
			}
		}
	}

	::v-deep .article-wrapper {
		scroll-snap-type: y mandatory;
	}

	::v-deep .article-wrapper__body {

		.stride__section {
			scroll-snap-align: start;
		}
	}
}
</style>
